import {Component, computed, input, OnDestroy, OnInit, output, signal} from '@angular/core';
import {FilterGroupListComponent} from "../../filter/filter-group-list/filter-group-list.component";
import {ProductSearchRequest} from "../../../portal/search/model/search-api";
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop";
import {filter, merge, switchMap} from "rxjs";
import {map, tap} from "rxjs/operators";
import {ProductFacadeService} from "../../../portal/product/model/product-facade.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {FilterGroup} from "../../filter/filter-group/filter-group.component";
import {ActivatedRoute, convertToParamMap, ParamMap} from "@angular/router";
import {HomeService} from "../../../home/model/home.service";
import {
  defaultSearchParams,
  ProductState,
  SearchParams,
  SearchResponse
} from "../../../portal/product/model/facade.model";
import {FilterChipItems} from "../../filter/filter-chips/filter-chips.component";

export enum ContentCategoryType {
  PRODUCTS = "products",
  SERIES = "series",
  DOCUMENTS = "documents",
  NONE = "none",
}

@Component({
    selector: 'app-category-filter-list',
    imports: [
        FilterGroupListComponent,
        TranslateModule
    ],
    templateUrl: './category-filter-list.component.html',
    styleUrl: './category-filter-list.component.scss'
})
export class CategoryFilterListComponent implements OnInit, OnDestroy {
  readonly DEFAULT_PAGE_SIZE = 25;
  searching: boolean = false;
  pageSize: number = this.DEFAULT_PAGE_SIZE;
  pageIndex: number = 0;
  totalSize: number = -1;
  filterListTitleSuffix = '';
  initialProductSize = 0;

  response = signal<SearchResponse | null>(null);
  state = this.productFacadeService.productState;
  request: ProductSearchRequest = new ProductSearchRequest([], [], [], '', this.translate.currentLang);

  categoryType = input.required<ContentCategoryType>();
  filterChipItems = input<FilterChipItems | undefined>(undefined);
  productId = input<string | undefined>('');
  categorySelectorVisible = input(true);
  showFilters = computed(() => this.state()?.filterGroups && this.categorySelectorVisible())
  productId$ = toObservable(this.productId).pipe(
    map(() => convertToParamMap(this.productId() ? {product: this.productId()} : {})),
    filter(params => params.keys.length > 0)
  )

  requestChanged = output<ProductSearchRequest>();
  responseChanged = output<Partial<SearchResponse> | null>()
  private state$ = toObservable(this.state)
  .pipe(
    filter((resp) => !!resp)
  );

  constructor(
    private productFacadeService: ProductFacadeService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private home: HomeService
  ) {
    this.state$.pipe(
      tap((state) => this.handleCategorySelectionChanged(state)),
      takeUntilDestroyed()
    ).subscribe();

    merge(
      this.home.search.pipe(
        map((search) => ({fullTextSearch: search}))
      ),
      this.translate.onLangChange.pipe(
        map(evt => {
          return {lang: evt.lang || ''}
        }))
    ).pipe(takeUntilDestroyed())
    .subscribe(value => this.handleRequestChanged(value))
  }

  ngOnInit() {
    merge(this.route.queryParamMap, this.productId$).pipe(
      switchMap((params) => {
        if (params.keys.length) {
          const request = this.handleUrlParamsChanged(params);
          return this.productFacadeService.loadRootCategories(this.categoryType(), request)
        }

        return this.productFacadeService.loadRootCategories(this.categoryType());
      })
    ).subscribe();
  }


  ngOnDestroy(): void {
    this.home.executeSearch('')
  }

  handleCategorySelectionChanged(event: ProductState | undefined) {
    if (event && event.filterGroups?.length) {
      const applicationId = this.getSelectedValue(event.filterGroups[0])!;
      const technologyId = this.getSelectedValue(event.filterGroups[1])!;
      this.request.selectedApplicationId = applicationId;
      this.request.selectedTechnologyId = technologyId;
      const productResponseSize = event.productResponse?.entries.totalSize;
      this.setInitialResponseSize(productResponseSize);
      this.filterListTitleSuffix = this.hasNewProductResponseSize(productResponseSize) ? this.translate.instant('category.filter.suffix', {
        count: productResponseSize,
        initialCount: this.initialProductSize,
        category: this.translate.instant(`category.${this.categoryType()}`)
      }) : this.translate.instant('category.filter.rootSuffix', {
        count: this.initialProductSize,
        category: this.translate.instant(`category.${this.categoryType()}`)
      });

      this.request.categoryIds = [applicationId, technologyId];
      this.responseChanged.emit(event.productResponse!)
    }
  }

  onFilterSelected(value: any) {
    const payload = value.value;
    payload.selectedValue = value;

    this.productFacadeService.updateState(payload).subscribe();
  }

  onFilterCleared(payload: any) {
    this.productFacadeService.clearState(payload).subscribe();
  }

  onFiltersReset() {
    this.productFacadeService.loadRootCategories(this.categoryType(), defaultSearchParams).subscribe();
  }

  protected handleRequestChanged(value: any) {
    this.pageIndex = 0;
    this.requestChanged.emit(this.request);
    // if (!value.documentTypes.length) {
    //   value = {
    //     documentTypes: value.documentTypes,
    //     categoryIds: [],
    //     categoryId: ''
    //   }
    // }
    this.productFacadeService.updateProductResponse(this.pageIndex, this.pageSize, value)
    .subscribe()
  }

  private getSelectedValue(group: FilterGroup[]) {
    if (group.length) {
      return group.slice(-1)[0].groupId;

    }
    return ''
  }

  private setInitialResponseSize(productResponseSize: number | undefined) {
    if (!this.initialProductSize && productResponseSize) {
      this.initialProductSize = productResponseSize;
    }
  }

  private hasNewProductResponseSize(productResponseSize: number | undefined) {
    return productResponseSize && this.initialProductSize && this.initialProductSize !== productResponseSize;
  }

  private handleUrlParamsChanged(value: ParamMap): Partial<SearchParams> {
    let query = value.get('q') ?? ""
    let technologyIds: string[] = value.get('t')?.split(',') ?? []
    let ca = value.get('ca') ?? ''
    let ct = value.get('ct') ?? ''
    let seriesIds: string[] = value.get('series')?.split(',') ?? []
    let productIds: string[] = value.get('product')?.split(',') ?? []
    let country = value.get('country') ?? ''
    let documentTypes: string[] = value.get('t')?.split(',') ?? []

    return {
      query,
      documentTypes,
      technologyIds,
      ca,
      ct,
      seriesIds,
      productIds,
      country
    }
  }

}
