import {Component, input, output} from '@angular/core';
import {Filter} from "../filter.const";
import {FilterComponent} from "../filter/filter.component";
import {MatExpansionModule} from "@angular/material/expansion";

export interface FilterGroup {
  groupId?: string;
  groupName?: string;
  filters: Filter[];
}

@Component({
    selector: 'app-filter-group',
    imports: [FilterComponent, MatExpansionModule],
    templateUrl: './filter-group.component.html',
    styleUrl: './filter-group.component.scss'
})
export class FilterGroupComponent {
  filters = input.required<Filter[]>();
  filterSelected = output<any>();
  filterCleared = output<unknown>();
  groupTitle = input<string>('');
}
